<template>
    <div>
        <HeaderBar />

        <main class="main">
            <section class="section-dashboard border-top">
                <div class="container">
                    <h2 class="section-title">
                        Shipping modules
                    </h2>

                    <template v-if="isLoadingModules || isLoadingModuleAccess">
                        Loading modules...
                    </template>

                    <template v-else-if="!hasAccessToModules || (!hasInboundAccess && !hasStorageAccess && !hasOutboundAccess)">
                        No modules available.
                    </template>

                    <template v-else>
                        <div class="card-list sm">
                            <div class="card-item-wrapper" v-if="hasInboundAccess">
                                <router-link :to="{name: 'ShippingInboundDashboard'}" class="card-item">
                                    <div class="card-item-img">
                                        <img :src="require('@/assets/images/colored-icons/inbound.svg')" class="img-fluid"
                                             alt="">
                                    </div>

                                    <span class="d-block pt-1">
                                        INBOUND
                                    </span>
                                </router-link>
                            </div>

                            <div class="card-item-wrapper" v-if="hasStorageAccess">
                                <router-link :to="{name: 'ShippingStorageDashboard'}" class="card-item">
                                    <div class="card-item-img">
                                        <img :src="require('@/assets/images/colored-icons/inventory.svg')" class="img-fluid"
                                             alt="">
                                    </div>

                                    <span class="d-block pt-1">
                                        STORAGE
                                    </span>
                                </router-link>
                            </div>

                            <div class="card-item-wrapper" v-if="hasOutboundAccess">
                                <router-link :to="{name: 'ShippingOutboundDashboard'}" class="card-item">
                                    <div class="card-item-img">
                                        <img :src="require('@/assets/images/colored-icons/inbound.svg')" class="img-fluid"
                                             alt="">
                                    </div>

                                    <span class="d-block pt-1">
                                        OUTBOUND
                                    </span>
                                </router-link>
                            </div>
                        </div>
                    </template>
                </div>
            </section>

            <div class="scrollable-area">
                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <router-link :to="{name: 'ModuleSelect'}" class="btn btn-secondary">
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar
        },
        data() {
            return {
                initLoaded: true,
                isLoadingModules: false,
                isLoadingModuleAccess: false,
                hasAccessToModules: false,
                hasInboundAccess: false,
                hasStorageAccess: false,
                hasOutboundAccess: false,
            };
        },
        mounted() {
            this.$root.$on('loadedRoleModuleAccess', () => {
                this.isLoadingModuleAccess = false;
                this.moduleAccess = this.$store.state.roleAccess?.['role-module-access']?.[this.$store.state.users.currentUser.ROLE_ID];

                const shippingInboundModuleAccess = this.moduleAccess?.['shipping']?.['inbound'] ?? undefined;
                const shippingStorageModuleAccess = this.moduleAccess?.['shipping']?.['storage'] ?? undefined;
                const shippingOutboundModuleAccess = this.moduleAccess?.['shipping']?.['outbound'] ?? undefined;

                /* Inbound */
                const shippingCanReceiveToManifest = shippingInboundModuleAccess?.['receive-to-manifest']?.ACCESS;
                const shippingCanReceiveToMasterBox = shippingInboundModuleAccess?.['receive-to-master-box']?.ACCESS;
                this.hasInboundAccess = shippingCanReceiveToManifest || shippingCanReceiveToMasterBox;

                /* Storage */
                const shippingCanViewManifests = shippingStorageModuleAccess?.['view-manifests']?.ACCESS;
                const shippingCanGenerateBarcodes = shippingStorageModuleAccess?.['generate-barcodes']?.ACCESS;
                this.hasStorageAccess = shippingCanViewManifests || shippingCanGenerateBarcodes;

                /* Outbound */
                let canShipManifests = shippingOutboundModuleAccess?.['ship-manifests']?.ACCESS;
                let canShipShipments = shippingOutboundModuleAccess?.['ship-shipments']?.ACCESS;
                this.hasOutboundAccess = canShipManifests || canShipShipments;
            });
        },
        created() {
            this.isLoadingModules = true;
            this.isLoadingModuleAccess = true;

            this.$store.dispatch('userSettings/getSelectedLocation', module).then((data) => {
                this.currentLocation = data;

                this.isLoadingModules = false;
                this.hasAccessToModules = this.currentLocation != null;
            });
        },
        methods: {}
    }
</script>

<style scoped>
    .card-item-wrapper.inactive {
        filter: grayscale(60%);
        opacity: 0.3;
    }
</style>
