<template>
    <div>
        <HeaderBar title="Module select"></HeaderBar>
        <main class="main">
            <section class="section-dashboard" v-if="hasControlTowerAccess">
                <div class="container">
                    <h2 class="section-title">Control Tower</h2>

                    <div class="card-list">
                        <div class="card-item-wrapper">
                            <router-link to="dashboard" class="card-item sm">
                                <div class="card-item-img">
                                    <img :src="require('@/assets/images/colored-icons/browser.svg')" class="img-fluid"
                                         alt="">
                                </div>

                                <span class="d-block pt-2">DASHBOARD</span>
                            </router-link>
                        </div>

                        <div class="card-item-wrapper inactive">
                            <a href="#" class="card-item sm">
                                <div class="card-item-img">
                                    <img :src="require('@/assets/images/colored-icons/package.svg')" class="img-fluid"
                                         alt="">
                                </div>

                                <span class="d-block pt-2">RETURNS</span>
                            </a>
                        </div>

                        <div class="card-item-wrapper">
                            <router-link to="inventory" class="card-item sm">
                                <div class="card-item-img">
                                    <img :src="require('@/assets/images/colored-icons/inventory.svg')" class="img-fluid"
                                         alt="">
                                </div>

                                <span class="d-block pt-2">INVENTORY</span>
                            </router-link>
                        </div>

                        <div class="card-item-wrapper">
                            <router-link :to="{name: 'InventoryCompletedWorkOrderOverview'}" class="card-item sm">
                                <div class="card-item-img">
                                    <img :src="require('@/assets/images/colored-icons/test.svg')" class="img-fluid"
                                         alt="">
                                </div>

                                <span class="d-block pt-2">WORK ORDERS</span>
                            </router-link>
                        </div>

                        <div class="card-item-wrapper inactive">
                            <a href="#" class="card-item sm">
                                <div class="card-item-img">
                                    <img :src="require('@/assets/images/colored-icons/sheet.svg')" class="img-fluid"
                                         alt="">
                                </div>

                                <span class="d-block pt-2">SALES ORDERS</span>
                            </a>
                        </div>

                        <div class="card-item-wrapper inactive">
                            <a href="#" class="card-item sm">
                                <div class="card-item-img">
                                    <img :src="require('@/assets/images/colored-icons/lamp.svg')" class="img-fluid"
                                         alt="">
                                </div>

                                <span class="d-block pt-2">INSIGHTS</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-dashboard border-top">
                <div class="container">
                    <h2 class="section-title">Sections</h2>

                    <template v-if="isLoadingModules || isLoadingModuleAccess">
                        Loading sections...
                    </template>

                    <template v-else-if="!hasAccessToModules">
                        You need to be signed in to a location to access the sections.
                    </template>

                    <template v-else-if="!hasShippingAccess && !hasReturnAccess">
                        No sections available.
                    </template>

                    <template v-else>
                        <div class="card-list sm">
                            <div class="card-item-wrapper" v-if="hasShippingAccess">
                                <router-link :to="{name: 'ShippingModuleSelect'}" class="card-item">
                                    <div class="card-item-img">
                                        <img :src="require('@/assets/images/colored-icons/inbound.svg')"
                                             class="img-fluid"
                                             alt="">
                                    </div>

                                    <span class="d-block pt-1">
                                        SHIPPING
                                    </span>
                                </router-link>
                            </div>

                            <div class="card-item-wrapper" v-if="hasReturnAccess">
                                <router-link :to="{name: 'ReturnModuleSelect'}" class="card-item">
                                    <div class="card-item-img">
                                        <img :src="require('@/assets/images/colored-icons/outbound.svg')"
                                             class="img-fluid"
                                             alt="">
                                    </div>

                                    <span class="d-block pt-1">
                                        RETURN
                                    </span>
                                </router-link>
                            </div>
                        </div>
                    </template>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar
        },
        data() {
            return {
                initLoaded: true,
                isLoadingModules: false,
                isLoadingModuleAccess: false,
                hasAccessToModules: false,
                hasControlTowerAccess: false,
                hasShippingAccess: false,
                hasReturnAccess: false,
            };
        },
        mounted() {
            this.$root.$on('loadedRoleModuleAccess', () => {
                this.isLoadingModuleAccess = false;
                this.moduleAccess = this.$store.state.roleAccess?.['role-module-access']?.[this.$store.state.users.currentUser.ROLE_ID];

                /**
                 * General
                 */
                /* Control tower */
                this.hasControlTowerAccess = this.moduleAccess?.['general']?.['control tower']?.['control-tower']?.ACCESS;

                /**
                 * Shipping
                 */
                const shippingInboundModuleAccess = this.moduleAccess?.['shipping']?.['inbound'] ?? undefined;
                const shippingStorageModuleAccess = this.moduleAccess?.['shipping']?.['storage'] ?? undefined;
                const shippingOutboundModuleAccess = this.moduleAccess?.['shipping']?.['outbound'] ?? undefined;

                /* Inbound */
                const shippingCanReceiveToManifest = shippingInboundModuleAccess?.['receive-to-manifest']?.ACCESS;
                const shippingCanReceiveToMasterBox = shippingInboundModuleAccess?.['receive-to-master-box']?.ACCESS;
                const shippingHasInboundAccess = shippingCanReceiveToManifest || shippingCanReceiveToMasterBox;

                /* Storage */
                const shippingCanViewManifests = shippingStorageModuleAccess?.['view-manifests']?.ACCESS;
                const shippingCanGenerateBarcodes = shippingStorageModuleAccess?.['generate-barcodes']?.ACCESS;
                const shippingHasStorageAccess = shippingCanViewManifests || shippingCanGenerateBarcodes;

                /* Outbound */
                const shippingCanShipManifests = shippingOutboundModuleAccess?.['ship-manifests']?.ACCESS;
                const shippingCanShipShipments = shippingOutboundModuleAccess?.['ship-shipments']?.ACCESS;
                const shippingHasOutboundAccess = shippingCanShipManifests || shippingCanShipShipments;

                this.hasShippingAccess = shippingHasInboundAccess || shippingHasStorageAccess || shippingHasOutboundAccess;

                /**
                 * Return
                 */
                const returnInboundModuleAccess = this.moduleAccess?.['return']?.['inbound'] ?? undefined;
                const returnStorageModuleAccess = this.moduleAccess?.['return']?.['storage'] ?? undefined;
                const returnOutboundModuleAccess = this.moduleAccess?.['return']?.['outbound'] ?? undefined;

                /* Inbound */
                let canReceiveReturn = returnInboundModuleAccess?.['receive-return']?.ACCESS;
                let canReceiveToManifest = returnInboundModuleAccess?.['receive-to-manifest']?.ACCESS;
                let canSortToManifest = returnInboundModuleAccess?.['sort-to-manifest']?.ACCESS;
                let canViewIncomingUnsortedManifests = returnInboundModuleAccess?.['view-incoming-unsorted-manifests']?.ACCESS;
                let canReceiveIncomingUnsortedManifests = returnInboundModuleAccess?.['receive-incoming-unsorted-manifests']?.ACCESS;
                let canReceiveWorkOrders = returnInboundModuleAccess?.['receive-work-order']?.ACCESS;
                const hasReturnInboundAccess = canReceiveReturn || canReceiveToManifest || canSortToManifest || canViewIncomingUnsortedManifests || canReceiveIncomingUnsortedManifests || canReceiveWorkOrders;

                /* Storage */
                let canViewManifests = returnStorageModuleAccess?.['view-manifests']?.ACCESS;
                let canViewContainers = returnStorageModuleAccess?.['view-containers']?.ACCESS;
                let canViewWorkOrders = returnStorageModuleAccess?.['view-work-orders']?.ACCESS;
                const hasReturnStorageAccess = canViewManifests || canViewContainers || canViewWorkOrders;

                /* Outbound */
                let canShipUnsortedManifests = returnOutboundModuleAccess?.['ship-unsorted-manifests']?.ACCESS;
                let canShipSortedManifests = returnOutboundModuleAccess?.['ship-sorted-manifests']?.ACCESS;
                let canShipShipments = returnOutboundModuleAccess?.['ship-shipments']?.ACCESS;
                let canShipContainers = returnOutboundModuleAccess?.['ship-containers']?.ACCESS;
                let canShipWorkOrders = returnOutboundModuleAccess?.['ship-work-orders']?.ACCESS;
                const hasReturnOutboundAccess = canShipUnsortedManifests || canShipSortedManifests || canShipShipments || canShipContainers || canShipWorkOrders;

                this.hasReturnAccess = hasReturnInboundAccess || hasReturnStorageAccess || hasReturnOutboundAccess;
            });
        },
        created() {
            this.isLoadingModules = true;
            this.isLoadingModuleAccess = true;

            this.$store.dispatch('userSettings/getSelectedLocation', module).then((data) => {
                this.currentLocation = data;

                this.isLoadingModules = false;
                this.hasAccessToModules = this.currentLocation != null;
            });
        },
        methods: {}
    }
</script>

<style scoped>
    .card-item-wrapper.inactive {
        filter: grayscale(60%);
        opacity: 0.3;
    }
</style>
